// handling radio grid and flex
.radio-custom-grid {
    display: grid;
    grid-template-columns:1fr 1fr;
    gap:40px;
    @media (max-width: 576px)
        {
        display: flex !important;
        }
    }

.login-button-justify-content{
  justify-content: start;
  @media (max-width: 576px)
  {
  justify-content: center ;
  }

}


// handling children space grid card
.grid-children-space{
  &>*{
    max-width: 447.33px;
    // min-width: 384px;
  }
  margin-inline: -10;
  display: grid;
  grid-template-columns:auto auto auto;
  gap: 24px;
  @media (max-width: 1625px)
  {
  grid-template-columns:auto auto;
  }
  @media (max-width: 576px)
  {
  grid-template-columns: auto;
  }

}

.custom-outline-btn{
  color:#DC3545 !important;
  background-color:transparent !important;
  border-color:#DC3545 !important;
  &:hover,&:active{
    color:#ffffff !important;
    background-color: #DC3545 !important;
    border-color: #DC3545 !important;
  }
}
  
 

// drop down in show view
.card-edit-menu ul>li>svg>path {
  color: #757575;
}
.card-edit-menu ul>li>div {
  color: #757575;
}

// label style
label.custom-label-style {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    
}

label.custom-label-style > span {
    color:#E02D39;
}

// radio inout customization
input[type="radio"] {
    display: none;
  }
  
  input[type="radio"]+label:before {
    content: "";
    /* create custom radiobutton appearance */
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 4px;
    margin-right: 6px;
    /* background-color only for content */
    background-clip: content-box;
    border: 2px solid #7C7C7C;
    background-color: #FFFFFF;
    border-radius: 50%;
  }
  
  body[dir="rtl"]{
    input[type="radio"]+label:before {
      content: "";
      margin-left: 6px;
      /* background-color only for content */
      
    }
  }
  
  
  /* appearance for checked radiobutton */
  input[type="radio"]:checked + label:before {
    background-color: #E02D39;
  }
  
  /* optional styles, I'm using this for centering radiobuttons */
  label {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  iframe {
    min-width: 100% !important;
  }

  .disabled{
    background-color: #D6D6D6 !important;
  }

  .cancel-button{
    background-color: transparent !important;
    color: #E02D39 !important;
    border-color: transparent !important;
    font-weight: 600;
  }

  .is-INVALID{
    border-bottom: 1px solid #E02D39;
  }


  .contact-us{
    display: grid;
    grid-template-columns:1fr 1fr;
    gap:40px;
    @media (max-width: 774px)
        {
        display: block !important;
        }
  }

  .react-tel-input.custom-phone-style .form-control {
    position: relative;
    font-size: 14px;
    letter-spacing: .01rem;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 48px;
    margin-left: 0;
    background: #FFFFFF;
    border: 1px solid #CACACA;
    border-radius: 5px;
    line-height: 25px;
    height: 40px;
    width: 100%;
    outline: none;
}



.react-tel-input.custom-phone-style .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: transparent;
  border: 1px solid #cacaca;
  border-radius: 3px 0 0 3px;
}

  .MuiBreadcrumbs-separator{
    color: #29366A;
    font-size: 25px;
    position: relative;
    top: -2px;
    }

    div[class*="react-bootstrap-table"]{
      table{
        thead{
          background-color:#DC3545 !important;
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          border-color: #DC3545;
          position: sticky;
          position: -webkit-sticky;
          top:0;
        }
        tbody{
          background-color: #FFFFFF !important;
          border-width: 0px 1px 1px 1px;
          border-style: solid;
          border-color: #7C7C7C;
        }
      }
    }

    .table.table-head-custom thead th {
      font-weight: 400;
      color: #FFFFFF !important;
      text-transform: none;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 0.85rem;
      line-height: 1.2rem;
    }

    .svg-icon.svg-icon-darkBlue svg g [fill] {
      transition: fill 0.3s ease;
      fill: #1B4883 !important;
  }


  $fontSize: 24;
  @for $i from 1 through $fontSize{
    .fontSize-#{$i}{
      font-size: #{$i}px !important;
      @media (max-width: 576px)
      {
        font-size: #{$i*0.75}px !important;

      }

    }
  }
  .Work-Sans{
    font-family: 'Work Sans', sans-serif;
  }


  .sub-title{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.75rem;
    color: #303030;
  }

  $gaps: (6, 8 , 12, 16, 18, 24, 32,40, 48);
  @each $gap in $gaps {
    .gap-#{$gap} {
      gap: #{$gap}px;
      @media (max-width: 576px)
      {
      gap: 0px;
      }
    }
  }

  $gaps: (6, 8 , 12, 16, 18, 24, 32,40, 48);
  @each $gap in $gaps {
    .gaps-#{$gap} {
      gap: #{$gap}px;
    }
  }


  $flex: 4;
  @for $i from 1 through $flex{
  .flex-#{$i}{
    flex:$i;
    @media (max-width: 576px)
         {
         flex: 0;
         }
     }
    }

  .scrollable{
    max-height: 489px;
  }


  .responsive-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    height:100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }


  div[class*="measure-table"]{
    table{
      flex:1;
      thead{
        background-color:#DC3545 !important;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #DC3545;
        th{
          text-align: center !important;
        }

      }
      tbody{
        background-color: #FFFFFF !important;
        border-width: 0px 1px 1px 1px;
        border-style: solid;
        border-color: #7C7C7C;
       
          
      
        td{
          text-align: center !important;

          
        }
      }
    }
  }

  .svg-icon.svg-icon-darkBlue svg g [fill] {
    transition: fill 0.3s ease;
    fill: #1B4883 !important;
}

.flip-Position{
  margin-top: -295px !important;
}

.outline-button{
  background-color: transparent !important;
  color: #E02D39 !important;
  border-color: #E02D39 !important;
  font-weight: 600;
}

#rc-imageselect{
  z-index: 1000;
}

.glow {
  position: relative;
  color:#E02D39;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  outline: none;
  animation: animate  2s linear infinite 

}

@keyframes animate 
{
  0%,50%,80%,92%
  {
    color:black;
    box-shadow: none;
    opacity: 0.5;
  }
  18.1%,50.1%,80.1%,92.1%
  {
    color:#E02D39;
    opacity: 1;
  }
}

.inscription-card-grid{
  display: grid;
  grid-template-columns:  0.2fr 2fr;
  grid-template-rows: 1fr; 
  gap: 1rem;

  @media (max-width: 810px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    row-gap: 1rem;
  }
}


.medical-folder-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(4, 0.2fr);
  gap:1rem;
  @media (max-width: 810px)
        {
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        row-gap: 1rem;
        }
}

.folder-card-header{
  grid-area: 1 / 1 / 2 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}
.growth-table-title{
  grid-area: 2 / 1 / 3 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}
.data-table-growth{
  grid-area: 3 / 1 / 5 / 2;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}


.mapi-card{
  grid-area: 3 / 2 / 4 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.allergy-card{
  grid-area: 3 / 2 / 4 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;

  }
}
           
.growth-graph-title{
  grid-area: 5 / 1 / 6 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.growth-graph{
  grid-area: 6 / 1 / 7 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.head-circumference-table-title{
  grid-area: 7 / 1 / 8 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}
.data-table-head-circumference{
  grid-area: 8 / 1 / 9 / 3;
  @media (max-width: 810px)
  {
    grid-area: auto;
  }
}

.login-top-bar{
  @media (max-width:991px) {
    width:100vw;
    position:fixed;
    top:0;
    z-index:4;
    background-color:white;
  }
}

.responsive-bg-login{
  background-size:cover !important;
  @media (max-width:579px) {
    background-size:contain !important;
  }
}


.grid-inscription-card{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  @media (max-width: 810px)
        {
          grid-template-columns: repeat(6, 1fr);
          grid-template-rows: repeat(2, 1fr);        
        }
}

.inscription-card-avatar{
  grid-area: 1 / 1 / 1 / 1;
  margin: auto auto 0 auto;
  @media (max-width: 810px)
        {
          grid-area: 1 / 1 / 3 / 1;   
          margin: auto;   
        }
}

.inscription-card-title{
  grid-area: 1 / 2 / 1 / 5;
  margin: auto auto auto 0;
  body[dir*="rtl"] &{
    margin: auto 0 auto auto;
  }
  @media (max-width: 810px)
  {
    grid-area: 1 / 2 / 1 / 7;      
  }
}

.inscription-card-description{
  grid-area: 2 / 2 / 2 / 5;
  margin: auto auto auto 0;
  body[dir*="rtl"] &{
    margin: auto 0 auto auto;
  }
  @media (max-width: 810px)
  {
    grid-area: 2 / 2 / 2 / 7;      
  }
}

.inscription-method-grid{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr); 
  gap:1rem;
  margin: 5rem;
  @media (max-width: 600px) {
    .inscription-method-grid {
      display: flex;
      flex-direction: column;
      padding-inline: 10px;
      padding-bottom: 10rem;
      margin: 0rem;
    }
  }
  
 
  @media (max-width: 1200px) {
    .inscription-method-grid {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;
      padding-inline: 15px;
    }
  }
  @media (max-width: 810px)
        {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding-inline: 20px;
        padding-bottom: 16rem;
        margin:0rem;
        }
}

.svg-responsive-screen{
  height: 50px !important;
  width: 50px !important;
  @media (max-width: 810px)
  {
    height: 30px !important;
    width: 30px !important;
  }
}


